import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FiLock } from "react-icons/fi";
import stimg from "./stripe.png";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import up from "./up.jpg";

const Footer = () => {
  return (
    <footer className="section-sm bg-tertiary mt-5" id="contact">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-4 col-6 mb-4">
            <div className="footer-widget">
              <h5 className="mb-4 text-primary font-secondary">Our Contact</h5>
              <p className="mail">
                <span>Mail:</span> Info@ficoven.com
              </p>
              <p>
                <span>Phone :</span> +44 7723 926516 / +44 7555 767355 /
                +2347020217807
              </p>
              <p>
                <span>Location:</span> No 1 Nwadiolu Street, Aboru, Iyana Ipaja,
                Lagos, Nigeria.
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-4">
            <div className="footer-widget">
              <h5 className="mb-4 text-primary font-secondary">Quick Links</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <Link to="/">Home</Link>
                </li>
                <li className="mb-2">
                  <HashLink to="/#about">About Us</HashLink>
                </li>
                <li className="mb-2">
                  <HashLink to="/#services">Our Services</HashLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-4">
            <div className="footer-widget">
              <h5 className="mb-4 text-primary font-secondary">Verification</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <HashLink to="/#verification">
                    Identity Verification Online
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 newsletter-form">
            <div style={{ backgroundColor: "#F4F4F4", padding: "35px" }}>
              <h5 className="mb-4 text-primary font-secondary">About Us</h5>
              <div className="pe-0 pe-xl-5">
                <p className="mb-0">
                  Ficoven investments limited is an inventive and worth driven
                  organisation in the business of Telecommunications,
                  Information technology, Data capturing/processing, engineering
                  and education.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center mt-5 text-center text-md-start">
          <div className="col-lg-4">
            <Link to="/">
              <img
                loading="prelaod"
                decoding="async"
                className="img-fluid"
                width="160"
                src="images/logo.png"
                alt="Ficoven"
              />
            </Link>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
            <ul className="list-unstyled list-inline mb-0 text-lg-center">
              <li className="list-inline-item me-4">
                <Link to="/" className="text-black">
                  Privacy Policy
                </Link>
              </li>
              <li className="list-inline-item me-4">
                <Link to="/" className="text-black">
                  Terms &amp; Conditions
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 text-md-end mt-4 mt-md-0">
            <ul className="list-unstyled list-inline mb-0 social-icons">
              <li className="list-inline-item me-3">
                <Link
                  to="/"
                  title="Explorer Facebook Profile"
                  className="text-black"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </Link>
              </li>
              <li className="list-inline-item me-3">
                <Link
                  to="/"
                  title="Explorer Twitter Profile"
                  className="text-black"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </Link>
              </li>
              <li className="list-inline-item me-3">
                <Link
                  to="/"
                  title="Explorer Instagram Profile"
                  className="text-black"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 offset-3 text-center mt-3">
            <span style={{ color: "green" }}>Secure Payment Powered by</span>
            <img height="auto" width="100" src={stimg} alt="Stripe" />
            <FiLock size={20} color="green" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
