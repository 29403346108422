import Banner from './Banner';
import AboutSection from './AboutSection';
import Service from './Service';
import VerificationSection from './VerificationSection';

const Content = ({ nin, setNin, email, setEmail, voucher, setVoucher, handleSubmit, showInput, noshowInput, showVoucher, errorMessage, loading, showInput2, noshowInput2, showVoucher2, email2, setEmail2, setFname, fname, lname, setlname, setVoucher2, voucher2, errorMessage2, setErrorMessage2, gender, setgender, dob, setDob, handleSubmit2, loading2, showPay, handlePayment, displayPay }) => {
    return (
        <div>
            <Banner />
            <AboutSection />
            <Service /> 
            <VerificationSection 
                 nin={nin}
                 setNin={setNin}
                 email={email}
                 setEmail={setEmail}
                 voucher={voucher}
                 setVoucher={setVoucher}
                 handleSubmit={handleSubmit}
                 showInput={showInput}
                 noshowInput={noshowInput}
                 showVoucher={showVoucher}
                showInput2={showInput2}
                 noshowInput2={noshowInput2}
                showVoucher2={showVoucher2}                
                errorMessage={errorMessage}
                loading={loading}
                email2={email2}
                setEmail2={setEmail2}
                voucher2={voucher2}
                setVoucher2={setVoucher2}
                fname={fname}
                setFname={setFname}
                lname={lname}
                setlname={setlname}
                errorMessage2={errorMessage2}
                setErrorMessage2={setErrorMessage2}
                gender={gender}
                setgender={setgender}
                dob={dob}
                setDob={setDob}
                handleSubmit2={handleSubmit2}
                loading2={loading2}
                showPay={showPay}
                handlePayment={handlePayment}
                displayPay={displayPay}       
            /> 
        </div>
      
    )
}

export default Content
