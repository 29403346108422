import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [nin, setNin] = useState("");
  const [email, setEmail] = useState("");
  const [transid, setTransid] = useState(null);
  const [voucher, setVoucher] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [noshowInput, setnoShowInput] = useState(true);
  const [name, setname] = useState(null);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    nin: "",
    phone: "",
    reference: "",
  });

  const [loading, setLoading] = useState(false);

  const [showPay, setShowPay] = useState(true);

  const [ipAddress, setIpAddress] = useState(null);

  const [randomString, setRandomString] = useState(null);

  useEffect(() => {
    const getIpAddress = async () => {
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      setIpAddress(data.ip);
    };

    getIpAddress();
  }, []);

  const navigate = useNavigate();
  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        nin,
        setNin,
        email,
        setEmail,
        voucher,
        setVoucher,
        showInput,
        noshowInput,
        errorMessage,
        loading,
        showPay,
        navigate,
        ipAddress,
        setLoading,
        randomString,
        setRandomString,
        setShowPay,
        setErrorMessage,
        setShowInput,
        setnoShowInput,
        name,
        setname,
        transid,
        setTransid,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
