import Header from './Header';
import Content from './Content';
import Complete from './Complete';
import Footer from './Footer';
import { DataProvider } from './context/DataContext';
import { Routes, Route } from "react-router-dom";
import Payment from './Payment';
//import PrivacyPolicyModal from './PrivacyPolicyModal';



function App() {

  //const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);

 //const [selected, setSelected] = useState(null);

  return (
 
    <div>
      {/*!acceptedPrivacyPolicy && <PrivacyPolicyModal  />*/}
      <Header />
      <DataProvider>
      <Routes>
        <Route exact path="/" element={
          <Content />
        } />
          
       
        <Route path="/complete" element={<Complete />} />
        <Route path="/processpay" element={<Payment />} />

        <Route path="*" element={<div>404 not found</div>} />
            
        </Routes>
        </DataProvider>
      <Footer />
    </div>
  
  );
}

export default App;