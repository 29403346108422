import React from 'react'
import { HashLink } from 'react-router-hash-link';

const Service = () => {
    return (
        <div>
            <section className="section bg-tertiary" id="services">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6">
                            <div className="section-title">
                                <p className="text-primary text-uppercase fw-bold mb-3">Difference Of Us</p>
                                <h1>What Makes Us <br /> Different Form Other?</h1>
                                <div className="content mb-0 mt-4">
                                    <p>
                                        Delivering innovative solutions to assist firms achieve best practices, growth and compliance with general procurement and supply, integrating the classic approach to management consulting with cutting edge technology expertise
                                    </p>
                                    <p>
                                        Vision delivering innovative business ideas and growth which attracts business leaders to our organisation who desire result. Together we find value across boundaries, develop insights to act on and energise teams to sustain success. We are passionate about always doing the right things for our clients, our people and our communities in spite of potential obstacles
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-title">
                                <h1>Verification Made Easy</h1>
                                <div className="content mb-0 mt-4">
                                    <p>
                                        Verification made easy for Nigerians in diaspora, you can now verify your NIN from the comfort of your home without stress before your apply for your Nigerian International passport. We offer you a stres free Verification service giving you the confidence that your NIN is ready for use.
                                    </p>
                                    <p>
                                        With our Verification service you can be confident that your passport application is guaranteed having verified that your NIN is active through our service. We are here to give you rest of mind. Assurance that when you make application you are ready.
                                    </p>
                                    <p>
                                        We are NIMC approved Verification licencee which allows us to carry out the Verification services, we are also NIMC approved diaspora NIN enrolment service provider which allows us to enrol Nigerians living in diaspora.
                                    </p>
                                    <HashLink className="btn btn-primary mt-4" to="/#verification">Verify your Nigeria Identity</HashLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Service