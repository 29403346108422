import logo from './logo.png';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


const Header = () => {

    return (
        <header className="navigation bg-tertiary" id="home">
        <nav className="navbar navbar-expand-xl navbar-light text-center py-3 ">
            <div className="container">
                <a className="navbar-brand" href="#home">
                    <img loading="prelaod" decoding="async" className="img-fluid" width="160" src={logo}
                        alt="Ficoven" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item"> <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item "> <HashLink className="nav-link" to="/#about">About</HashLink>
                        </li>

                        <li className="nav-item "> <HashLink  className="nav-link" to="/#services">Services</HashLink>
                        </li>
                        <li className="nav-item "> <HashLink className="nav-link" to="/#contact">Contact</HashLink>
                        </li>
                        <li className="nav-item "> <HashLink className="nav-link" to="/#verification">Verify your Nigeria Identity
                                Online</HashLink>
                        </li>
                    </ul>
                   
                </div>
            </div>
        </nav>
    </header>
    )
}



export default Header;