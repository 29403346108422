import React from "react";
import api from "./api/posts";
import { useContext } from "react";
import { useEffect, useState } from "react";
import DataContext from "./context/DataContext";
import styles from "./styles.module.css";
import PaystackPop from "@paystack/inline-js";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import StripeCheckout from "react-stripe-checkout";
import { FiLock } from "react-icons/fi";
import stimg from "./stripe.png";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51M03yaE77BLP2l34f9sVHOrJzJd24UXXvI0z43ZpXRO8XnkhM7hgK6iGodr5g3gTsf2EBxokoEJcne5XksHprVng0077inhXIH"
);

//import { useRef } from 'react';

function Verification() {
  //const inputRef = useRef();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const [paymentStatus, setPaymentStatus] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");

  const unitClick = () => {
    setPaymentStatus("unit");
  };

  const stripe = useStripe();
  const elements = useElements();

  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (msg) {
      const newTimeoutId = setTimeout(() => {
        setMsg("");
      }, 9000);
      setTimeoutId(newTimeoutId);
    }
  }, [msg]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const [confirmEmail, setConfirmEmail] = useState("");

  const handleConfirmEmailChange = (event) => {
    setConfirmEmail(event.target.value);
  };

  const {
    data,
    setData,
    setTransid,
    voucher,
    setVoucher,
    showInput,
    noshowInput,
    loading,
    showPay,
    setLoading,
    setShowPay,
    setErrorMessage,
    setShowInput,
    setnoShowInput,
  } = useContext(DataContext);
  useEffect(() => {
    const storedData = localStorage.getItem("myData");
    if (storedData) {
      setTransid(storedData);
    }
  }, []);

  const handleClick = () => {
    setPaymentStatus("pay");
  };

  //var reply ="";

  function generateRandomString(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMsg("");
    if (isValidPhoneNumber(data.phone)) {
      if (paymentStatus === "pay") {
        try {
          const response = await api.post("/create-payment-intent", {
            amount: 10 * 100,
          });

          const { client_secret } = response.data;

          const result = await stripe.confirmCardPayment(client_secret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                email: data.email,
              },
            },
          });

          if (result.error) {
            setError(result.error.message);
          } else {
            try {
              setLoading(true);
              const url = "/veridetails";
              data.reference = generateRandomString(10);
              //console.log(data);
              //console.log(transaction.reference);
              const { data: res } = await api.post(url, data);
              setMsg(res.message);
              setData({
                firstName: "",
                lastName: "",
                dob: "",
                email: "",
                nin: "",
                phone: "",
                reference: "",
              });
              displayPay();
            } catch (error) {
              if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
              ) {
                setError(error.response.data.message);
              }
            } finally {
              setLoading(false); // Set isLoading back to false after response is received
            }
          }
        } catch (error) {
          if (
            error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500
          ) {
            setError(error.response.data.message);
          }
        } finally {
          setLoading(false); // Set isLoading back to false after response is received
        }
      }
      if (paymentStatus === "unit") {
        try {
          api
            .get(`/evoucher/${voucher}`)
            .then(async (response) => {
              if (response.status === 200) {
                try {
                  setLoading(true);
                  const url = "/veridetails";
                  const randomString = generateRandomString(10);
                  data.reference = randomString;
                  //console.log(data);
                  //console.log(transaction.reference);
                  const { data: res } = await api.post(url, data);
                  const dete = {
                    voucher: voucher,
                    usage: 2,
                  };

                  api
                    .put("/evoucher", dete)
                    .then((response) => {
                      setMsg(res.message);
                      setData({
                        firstName: "",
                        lastName: "",
                        dob: "",
                        email: "",
                        nin: "",
                        phone: "",
                        reference: "",
                      });
                      setVoucher("");
                      showVoucher();
                      // Do something with the response data
                    })
                    .catch((error) => {
                      console.log(error);
                      // Handle any errors that occurred during the request
                    });
                } catch (error) {
                  if (
                    error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                  ) {
                    setError(error.response.data.message);
                  }
                } finally {
                  setLoading(false); // Set isLoading back to false after response is received
                }
              }
              if (response.status === 204) {
                setError("Voucher does not exist please try another one");
              }
              // Do something with the response data
            })
            .catch((error) => {
              console.log(error);
              // Handle any errors that occurred during the request
            });
        } catch (error) {
          if (
            error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500
          ) {
            setError(error.response.data.message);
          }
        } finally {
          setLoading(false); // Set isLoading back to false after response is received
        }
      }
    } else {
      setError("Please enter a valid phone number with an international code.");
    }
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    //setTransid(1);
    setErrorMessage("");
  };

  /*useEffect(() => {
    // Trigger a state update after the component has rendered
    setTransid(reply);
  }, [reply]);*/

  const displayPay = () => {
    setError("");
    if (
      data.email !== confirmEmail ||
      data.email === "" ||
      confirmEmail === ""
    ) {
      setError("Email and Confirm Email do not match or are empty");
    } else {
      setShowPay(!showPay);
    }
  };

  const showVoucher = () => {
    setError("");
    if (
      data.email !== confirmEmail ||
      data.email === "" ||
      confirmEmail === ""
    ) {
      setError("Email and Confirm Email do not match or are empty");
    } else {
      setShowInput(!showInput);
      setnoShowInput(!noshowInput);
    }
  };
  return (
    <section
      className="verification-section section"
      id="verification"
      style={{ paddingTop: "50px", paddingBottom: "0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="section-title">
              <p className="text-primary text-uppercase fw-bold mb-3">
                Nigeria Identity Verification
              </p>
              <h4>Verify with National Identification Number</h4>
              <form className="searchNin" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">
                    First Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    placeholder="John"
                    required
                    onChange={handleChange}
                    value={data.firstName}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">
                    Last Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    placeholder="Doe"
                    required
                    onChange={handleChange}
                    value={data.lastName}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="dob" className="form-label">
                    Date of Brith<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="dob"
                    name="dob"
                    required
                    onChange={handleChange}
                    value={data.dob}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="+23471988332"
                    required
                    onChange={handleChange}
                    value={data.phone}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="nin" className="form-label">
                    NIN OR VNIN<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nin"
                    name="nin"
                    placeholder="12345678910"
                    required
                    value={data.nin}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email address<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                    required
                    onChange={handleChange}
                    value={data.email}
                  />
                  <div className="form-text">
                    Your verification result will be sent to your email please
                    use a valid email address
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmEmailInput" className="form-label">
                    Confirm Email<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="confirmEmail"
                    id="confirmEmailInput"
                    placeholder="Confirm email"
                    required
                    onChange={handleConfirmEmailChange}
                    value={confirmEmail}
                  />
                </div>

                {showInput && (
                  <div className="mb-3">
                    <label htmlFor="voucher" className="form-label">
                      Voucher<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="voucher"
                      placeholder="ABC1234X"
                      required
                      value={voucher}
                      onChange={(e) => setVoucher(e.target.value)}
                    />
                  </div>
                )}
                {!showPay && (
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                      style={{ color: "green", fontSize: 25 }}
                    >
                      Amount to Pay $10
                    </label>
                    <br />
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                      style={{ color: "green", fontSize: 12 }}
                    >
                      <span style={{ color: "green" }}>
                        Secure Payment Powered by
                      </span>
                      <img height="auto" width="70" src={stimg} alt="Stripe" />
                      <FiLock size={20} color="green" />
                    </label>
                  </div>
                )}

                {error && (
                  <div
                    className={
                      "w-100 px-2 alert alert-danger " + styles.error_msg
                    }
                  >
                    {error}
                  </div>
                )}
                {msg && (
                  <div
                    className={
                      "w-100 px-2 alert alert-success " + styles.success_msg
                    }
                  >
                    {msg}
                  </div>
                )}

                {loading && (
                  <div className="col-12 text-center">Loading...</div>
                )}

                {!showPay && (
                  <>
                    <div>
                      <CardElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#424770",
                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                        }}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary mt-4 d-block col-12"
                      onClick={handleClick}
                    >
                      Pay & Verify
                    </button>
                  </>
                )}

                {showInput && (
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 d-block col-12"
                    onClick={unitClick}
                  >
                    Submit
                  </button>
                )}
                {noshowInput && showPay && (
                  <button
                    type="button"
                    className="btn btn-primary mt-4 d-block col-12"
                    onClick={showVoucher}
                  >
                    Verify with voucher
                  </button>
                )}
                {noshowInput && showPay && (
                  <div>
                    <h4 className="text-center">OR</h4>
                    <button
                      className="btn btn-success d-block col-12"
                      onClick={displayPay}
                    >
                      Verify with Stripe
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <h4>Information</h4>
            <div
              style={{
                backgroundColor: "#393186",
                color: "white",
                padding: "20px",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <p>
                We want to assure all customers that every National
                Identification Number (NIN) will undergo a thorough verification
                process and will come back verified.
              </p>
              <p>
                Your verification result will be sent to the email address you
                provided. Please ensure that you provide a valid email address
                to receive the verification outcome.
              </p>
              <p>
                Please make sure that the information you provide matches the
                information in the national identity database. Accuracy in
                providing the required details is crucial for a successful
                verification process.
              </p>
            </div>
            <h4>Privacy Statement</h4>

            <div
              style={{
                backgroundColor: "green",
                color: "white",
                padding: "20px",
              }}
            >
              <p>
                At Ficoven , we are committed to protecting our customers'
                privacy and security. We collect and use personal information
                only to process transactions and provide services. We never
                disclose or sell personal information to third parties, and we
                use appropriate measures to protect personal information. If you
                have any questions, please contact us with email:
                info@ficoven.com.
              </p>
              <p>
                We collect and use personal information only to process
                transactions and to provide the best possible service to our
                customers.
              </p>
              <p>
                We will never disclose or sell personal information to any third
                party except to complete transactions with our customers. We
                will also never use personal information for any purpose other
                than to provide services to our customers, unless required by
                law.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default function VerificationSection() {
  return (
    <Elements stripe={stripePromise}>
      <Verification />
    </Elements>
  );
}
